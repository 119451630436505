import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TourComboDetailDTO } from '@assistant/angular-tour-ota-service';

interface IOption {
  name?: string;
  value?: Array<string>;
}

interface IOptionValueDTO {
  id?: string;
  value?: string;
  order?: number;
}

interface IOptionDTO {
  id?: string;
  order?: number;
  name?: string;
  value?: IOptionValueDTO;
}

export interface TourComboDTO {
  id?: string;
  order?: number;
  name?: string;
  price?: number;
  options?: Array<IOptionDTO>;
}

@Component({
  selector: 'app-tooltip-combo',
  templateUrl: './tooltip-combo.component.html',
  styleUrls: ['./tooltip-combo.component.scss'],
})
export class TooltipComboComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tourCombo: TourComboDTO;
  @Input() tourComboDetail: TourComboDetailDTO;
  @Input() isDisplayedPanelOnTop: boolean = false;
  @ViewChild('target') targetElement: ElementRef;
  @ViewChild('comboOptionsInfoPanel')
  comboOptionsInfoPanelElement: OverlayPanel;
  comboOptions: Array<IOption>;

  constructor(private rerender: Renderer2) {}

  ngOnInit(): void {
    this.handleTourComboOptions();
  }

  ngAfterViewInit(): void {
    if (this.isDisplayedPanelOnTop) {
      this.rerenderTargetElementLocation();
    }
  }

  ngOnDestroy(): void {
    if (this.comboOptionsInfoPanelElement) {
      this.comboOptionsInfoPanelElement.hide();
    }
  }

  handleTourComboOptions(): void {
    if (this.tourComboDetail) {
      const options = this.tourComboDetail?.options;
      let handledComboOptions: Array<IOption> = [];
      options?.forEach((res) => {
        if (res.id) {
          let comboName =
            res.name === 'Tour guide language' ? 'Tour guide' : res.name;
          handledComboOptions.push({
            name: comboName,
            value: res.values?.map((item) => item.value),
          });
        }
      });
      this.comboOptions = handledComboOptions;
    } else {
      const options = this.tourCombo.options as Array<IOptionDTO>;
      options?.sort((a, b) => {
        if (a.order !== b.order) {
          const orderA = (a.order as number) || 0;
          const orderB = (b.order as number) || 0;
          return orderA - orderB;
        } else {
          const orderValueA = (a.value?.order as number) || 0;
          const orderValueB = (b.value?.order as number) || 0;
          return orderValueA - orderValueB;
        }
      });

      const groupOptions = options?.map((option) => {
        let result: any = [];
        const optionName = option.name as string;
        if (!result.hasOwnProperty(option.name as string))
          result[optionName] = [];
        result[optionName].push(option.value!.value);
        return {
          name: option.name as string,
          value: [option.value!.value as string],
        };
      });

      const mergedOptions: Array<{ name: string; value: string[] }> = [];
      for (const option of groupOptions) {
        const existingOption = mergedOptions.find(
          (merged) => merged.name === option.name,
        );
        if (existingOption) {
          existingOption.value.push(...option.value);
        } else {
          mergedOptions.push({ name: option.name, value: option.value });
        }
      }

      this.comboOptions = mergedOptions;
      this.comboOptions.forEach((obj) => {
        if (obj.name === 'Tour guide language') {
          obj.name = 'Tour guide';
        }
      });
    }
  }

  rerenderTargetElementLocation() {
    this.comboOptionsInfoPanelElement.show(
      new MouseEvent('click'),
      this.targetElement.nativeElement,
    );
    setTimeout(() => {
      if (this.comboOptionsInfoPanelElement.container) {
        const panelHeight =
          (this.comboOptionsInfoPanelElement.container.offsetHeight || 0) + 16; // 16 is a px height of icon
        const targetElement = this.targetElement.nativeElement;
        this.rerender.setStyle(targetElement, 'top', `${-panelHeight}px`);
      }
      this.comboOptionsInfoPanelElement.hide();
    }, 0);
  }
}
