import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BehaviorSubject, map, of } from 'rxjs';
import { UserService as User } from '../user.service';
import { UserService } from '../user/user.service';
import { ModerateRole } from '@shared/constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userRoles = new BehaviorSubject<any>(
    this.userService.getUserInfoFromLocalStorage(),
  );

  constructor(
    private tokenStorageService: TokenStorageService,
    private userService: UserService,
    private user: User,
  ) {}

  async checkPermission(token?: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let hasPermission = false;
      let tokenId: string | null = '';
      if (token) {
        tokenId = token;
      } else {
        tokenId = this.tokenStorageService.getToken();
      }

      if (tokenId) {
        try {
          const res = await this.user.getModeratorRole().toPromise();
          if (res && res.length > 0) {
            hasPermission = res.some(
              (item) =>
                item.role === ModerateRole.SUPER_ADMIN.code ||
                (item.role === ModerateRole.ADMIN.code &&
                  item.type === 'MODULE_ADMIN' &&
                  item.type_moderator === 'TOUR_MODULE_ADMIN'),
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
      return resolve(hasPermission);
    });
  }
}
