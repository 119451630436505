export enum Breakpoint {
    DEFAULT = 0,
    XS = 375,
    SM = 480,
    MD = 768,
    LG = 1014,
    XL = 1336,
  }
  
  export namespace Breakpoint {
    export const current = (): Breakpoint => {
      const innerWidth = window.innerWidth;
      if (innerWidth >= Breakpoint.XL) return Breakpoint.XL;
      if (innerWidth >= Breakpoint.LG) return Breakpoint.LG;
      if (innerWidth >= Breakpoint.MD) return Breakpoint.MD;
      if (innerWidth >= Breakpoint.SM) return Breakpoint.SM;
      if (innerWidth >= Breakpoint.XS) return Breakpoint.XS;
      return Breakpoint.DEFAULT;
    };
  }
  
  export enum BreakpointMQ {
    xs = `(min-width: 375px)`,
    sm = `(min-width: 480px)`,
    md = `(min-width: 768px)`,
    lg = `(min-width: 1014px)`,
    xl = `(min-width: 1336px)`,
  }
  