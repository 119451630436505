import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enum/routes.enum';
import { ChatService } from '@shared/services/chat.service';
import { GetApiService } from '@shared/services/get-api.service';
import { headerEnum } from 'src/app/core/enum/header.enum';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isSmallScreen: boolean = false;
  @Input() user: any;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  translator = inject(CustomTranslateService);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  authService = inject(AuthService);
  getApiService = inject(GetApiService);
  chatService = inject(ChatService);

  adminHubUrl = environment.ADMIN_HUB_URL;
  iframeUrl: SafeResourceUrl;
  headerEnum = headerEnum;
  loadingSignOut = false;
  overlayVisible = false;
  adminSupportUrl = AppRoutes.ADMIN_SUPPORT;
  chatBadgeCount = '0';
  env = environment;
  ngOnInit(): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL,
    );
    this.getChatPendingCount();
    this.chatService.pendingSessionChanges.subscribe((value) => {
      if (value) {
        this.getChatPendingCount();
      }
    });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };
  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  handleSignOut = () => {
    // const frame = document.getElementById('socialLogin');
    // if (this.isIFrame(frame) && frame.contentWindow) {
    //   const messageData = {
    //     type: 'disconnect',
    //     data: {
    //       token: localStorage.getItem('accessToken'),
    //       user_id: localStorage.getItem('userId'),
    //     },
    //   };
    //   frame.contentWindow.postMessage(messageData, '*');
    // }
    // localStorage.clear();
    // window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
    const userProfileId = JSON.parse(
      localStorage.getItem('user_profile_admin') || '{}',
    )?.id;
    const currentDeviceInfo = JSON.parse(
      localStorage.getItem('device_sign_in') || '{}',
    );
    this.loadingSignOut = true;
    this.authService
      .logout({
        user_id: userProfileId,
        device: currentDeviceInfo?.device,
        device_id: currentDeviceInfo?.device_id,
        device_type: currentDeviceInfo?.device_type,
      })
      .subscribe();
    setTimeout(() => {
      this.loadingSignOut = false;
      localStorage.clear();
      this.connectPMServer();
    }, 1000);
  };

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = environment.IFRAME_CONNECT_URL;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'disconnect' }, '*');
    };
    ifr.onload = connect;
  }

  toggle() {
    this.overlayVisible = !this.overlayVisible;
  }

  getChatPendingCount() {
    this.getApiService.getAdminChatSessionsCount('PENDING', environment.CHAT_MODULE).subscribe({
      next: (res: any) => {
        this.chatBadgeCount = String(res?.data);
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
}
