import { Injectable, inject } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SentenceCasePipe } from '../pipes/sentence-case.pipe';
import { TitleCasePipe } from '@angular/common';
import { Locales } from '../constants/locales.config';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateService {
  sentenceCasePipe = inject(SentenceCasePipe);
  titleCasePipe = inject(TitleCasePipe);

  private languageSubject = new BehaviorSubject<any>({});
  language$: Observable<string> = this.languageSubject.asObservable();

  private translateService = inject(TranslateService);

  constructor() {
    this.initialization();
  }

  private initialization() {
    const lang = localStorage.getItem('lang');
    this.translateService.setDefaultLang(
      lang && Locales.includes(lang) ? lang : 'en',
    );
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageSubject.next(event.lang);
    });
  }

  setLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    try {
      this.translateService.use(lang);
    } catch (error) {
      console.warn('Cannot translate this language, apply English as default');
      this.translateService.use(this.translateService.getDefaultLang());
    }
  }

  getCurrentLanguage(): string {
    return (
      localStorage.getItem('lang') ||
      this.translateService.getBrowserLang() ||
      'en'
    );
  }

  transform(key: string): string {
    return this.translateService.instant(key) as string;
  }

  sentenceCase(value: string): string {
    value = this.transform(value);
    return this.sentenceCasePipe.transform(value);
  }

  titleCase(value: string): string {
    value = this.transform(value);
    return this.titleCasePipe.transform(value);
  }
}
