import { Component, OnInit, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Toast } from './core/utils/toast.util';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, Toast],
})
export class AppComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;

  environmentURL?: string = environment.FE_URL;
  sanitizer = inject(DomSanitizer);
  url?: string;
  iframeUrl: SafeResourceUrl;

  constructor() {}

  ngOnInit(): void {
    this.url = `${this.environmentURL}dashboard`;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL,
    );
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };
}
