import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideDownUp = trigger('slideDownUp', [
  state('void', style({ height: '0', opacity: '0' })),
  state('*', style({ height: '*', opacity: '1' })),
  transition(':enter, :leave', [animate('0.3s ease-out')]),
]);
