import { Component, Input, inject } from '@angular/core';
import { RoundNumberService } from '@core/services/round-number.service';
import { slideDownUp } from '@shared/animations/slide-down-up.animation';
export interface PriceDetails {
  subTotal: number;
  taxeAndCharge: number;
  vouchers: any[];
  coinsApplied: any;
  totalPrice: number;
  adultPrice: number;
  childPrice: number;
  totalAdult: number;
  totalChild: number;
  ageChild? : number;
  heightChild? : number;
  promotions?: any[];
  membershipDiscount?: any;
  package_options?: any[];
  totalInfant?: number;
  ageInfant? : number;
  infantPrice?: number;
}
@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss'],
  animations: [slideDownUp],
})
export class PriceDetailsComponent {
  public roundNumberService = inject(RoundNumberService);
  @Input() priceInfo: PriceDetails;
  isHideTaxes = true;
  prefixTranslation =
    'section-booking-helpdesk.booking-helpdesk-detail.price-details.';
}
