<p-toast [position]="'top-right'" [styleClass]="'right-1 max-w-[100vw]'">
  <ng-template let-message pTemplate="message">
    <div class="flex items-center gap-3">
      <i
        class="text-xl/5"
        [ngClass]="{
          'sctr-icon-check': message.severity === 'info',
          'sctr-icon-alert-triangle': message.severity === 'warn',
          'sctr-icon-x-circle': message.severity === 'error',
          'sctr-icon-check-circle': message.severity === 'success'
        }"
      ></i>
      <div class="text-sm font-semibold toast-content">
        {{ message.detail }}
      </div>
    </div>
  </ng-template>
</p-toast>
