<div class="body-wrapper w-full h-auto mx-auto flex flex-col bg-gray-50">
  <div
    class="t-reservation-details w-[59rem] lg:w-[1200px] max-w-[calc(100%_-_2_*_24px)] mx-auto"
    *ngIf="dataBooking; else skeletonTemplate"
  >
    <div class="t-reser-containt flex flex-row py-6 gap-6">
      <div class="flex flex-col gap-2">
        <img
          src="/assets/imgs/booking-helpdesk/reservation-details-banner.svg"
          class="w-full object-cover"
        />
        <app-reservation-card
          [tourAvatar]="dataBooking.avatar"
          [tourName]="dataBooking.tour_name"
          [packageOption]="dataBooking.package_options"
          [comboOption]="dataBooking.tour_combo"
        ></app-reservation-card>

        <!-- Contact information -->
        <div
          class="t-contact-infor justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
        >
          <p
            class="p-4 text-base font-semibold text-gray-900 w-[156px] xl:w-[204px]"
          >
            {{ prefixTranslation + "contact-information" | translate }}
          </p>
          <div
            class="flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between p-4 w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
          >
            <div
              class="t-guest-name flex flex-col gap-2 xl:gap-4 text-md w-full"
            >
              <p class="font-normal text-gray-700">
                {{ prefixTranslation + "guest-name" | translate }}
              </p>
              <p class="font-medium text-gray-900">
                {{ dataBooking.contact_info.full_name }}
              </p>
            </div>
            <div
              class="t-guest-name flex flex-col gap-2 xl:gap-4 text-md w-full"
            >
              <p class="text-md font-normal text-gray-700">
                {{ prefixTranslation + "phone-number" | translate }}
              </p>
              <div class="flex flex-row gap-2 font-medium text-gray-900 w-full">
                <input
                  class="border-none outline-none max-w-[113px] text-md text-gray-900 font-medium"
                  readonly
                  [value]="phoneNumber"
                  [type]="isShowPhoneNumber ? 'text' : 'password'"
                />
                <button
                  (click)="toggleShowPhoneNumber()"
                  class="w-5 h-5 flex items-center justify-center"
                >
                  <span
                    [ngClass]="{
                      'sctr-icon-eye-off': isShowPhoneNumber,
                      'sctr-icon-eye': !isShowPhoneNumber
                    }"
                    class="text-2xl/5 text-gray-700"
                  ></span>
                </button>
              </div>
            </div>
            <div
              class="t-guest-name flex flex-col gap-2 xl:gap-4 text-md w-full"
            >
              <p class="text-md font-normal text-gray-700">
                {{ "common.e-mail" | translate }}
              </p>
              <p class="font-medium text-primary-600">
                {{ dataBooking.contact_info.email }}
              </p>
            </div>
          </div>
        </div>

        <!-- Booking details -->
        <app-booking-details
          [departureDate]="dataBooking.start_date"
          [startTime]="dataBooking.start_time"
          [endDate]="dataBooking.end_date"
          [endTime]="dataBooking.end_time"
          [duration]="dataBooking.duration"
          [durationUnit]="dataBooking.duration_unit"
          [adultNumber]="dataBooking.total_adults"
          [childNumber]="dataBooking.total_children"
          [infantNumber]="dataBooking?.total_infants || 0"
          (viewGuestListEvent)="viewGuestListHandle()"
          [timezone]="dataBooking.timezone"
        ></app-booking-details>

        <!-- Price details -->
        <app-price-details [priceInfo]="priceInfo"></app-price-details>

        <!-- Special requests -->
        <div
          class="t-reser-details flex flex-row justify-between border border-solid border-gray-200 bg-white rounded-lg"
          *ngIf="
            (dataBooking?.special_request?.pickup_location &&
              dataBooking?.special_request.pickup_location.length > 0) ||
            (dataBooking?.special_request?.other &&
              dataBooking?.special_request.other.length > 0)
          "
        >
          <p
            class="p-4 whitespace-nowrap text-base font-semibold text-gray-900 w-[156px] xl:w-[204px]"
          >
            {{
              prefixTranslation + "special-requests" | translate | sentenceCase
            }}
          </p>
          <!-- <div
            class="p-4 flex flex-col gap-3 text-md font-normal text-gray-900 w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            *ngIf="
              dataBooking?.special_request &&
                dataBooking?.special_request?.pickup_location;
              else noSpecialRequest
            "
          >
            {{ dataBooking.special_request.pickup_location }}
          </div> -->
          <div
            class="p-4 flex flex-col gap-1 text-md font-normal text-[var(--light-color-scheme-gray-900)] w-[calc(100%-204px)]"
          >
            <li
              *ngIf="
                dataBooking?.special_request?.pickup_location &&
                dataBooking?.special_request.pickup_location.length > 0
              "
            >
              {{ dataBooking.special_request.pickup_location }}
            </li>
            <li
              *ngIf="
                dataBooking?.special_request?.other &&
                dataBooking?.special_request.other.length > 0
              "
            >
              {{ dataBooking.special_request.other }}
            </li>
          </div>
          <ng-template #noSpecialRequest>
            <div
              class="p-4 flex flex-col gap-3 text-md font-normal text-gray-900 w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              {{ "common.no" | translate | sentenceCase }}
              {{ prefixTranslation + "special-requests" | translate }}
            </div>
          </ng-template>
        </div>

        <!-- Tour policy -->
        <app-tour-details
          [cancelPolicy]="
            dataBooking.cancellation_policy
              ? dataBooking.cancellation_policy
              : ''
          "
          [childrenPolicy]="
            dataBooking.children_policy ? dataBooking.children_policy : ''
          "
        ></app-tour-details>
      </div>

      <!--  -->
      <div class="min-w-[348px] xl:min-w-[384px] flex flex-col gap-2">
        <!-- Booking information -->
        <app-booking-information
          [reservationCode]="dataBooking.reservation_code"
          [reservationStatus]="dataBooking.status"
          [reservationNetlPrice]="dataBooking.net_price"
          [reservationCommissPrice]="dataBooking.commission?.total"
          [reservationCommissPercent]="dataBooking.commission?.percent"
          [qrCode]="dataBooking.qr_code"
          [pinCode]="dataBooking.pin_code"
        ></app-booking-information>
        <!-- Actions -->
        <div
          *ngIf="getActionByStatus(dataBooking.status) !== 0"
          class="bg-white p-4 flex flex-col gap-4 rounded-lg border border-solid border-gray-200"
        >
          <h5 class="text-base font-semibold text-gray-900">
            {{ "common.actions" | translate }}
          </h5>
          <button
            class="t-guest-action-button capitalize w-full h-11 px-5 py-3 rounded-lg border border-solid border-gray-300 text-md font-semibold text-gray-900"
            (click)="showRejectDialog = true"
          >
            {{ "section-action.cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #skeletonTemplate>
    <div
      class="t-reservation-details w-[59rem] lg:w-[1200px] max-w-[calc(100%_-_2_*_24px)] mx-auto"
    >
      <div class="flex flex-row py-6 gap-6">
        <div class="flex flex-col gap-2">
          <img
            src="/assets/imgs/booking-helpdesk/reservation-details-banner.svg"
            class="w-full object-cover"
          />
          <div
            class="p-4 flex flex-row gap-2 rounded-lg border border-solid border-gray-200 bg-white h-[124px]"
          >
            <p-skeleton width="92px" height="92px"> </p-skeleton>
            <div class="flex flex-col gap-1">
              <p-skeleton width="500px" height="1.75rem"></p-skeleton>
              <p-skeleton width="200px" height="1.25rem"></p-skeleton>
              <p-skeleton width="300px" height="1.25rem"></p-skeleton>
            </div>
          </div>
          <div
            class="t-contact-infor justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
          >
            <div class="w-[156px] xl:w-[204px] p-4">
              <p-skeleton width="195px" height="1.5rem"></p-skeleton>
            </div>
            <div
              class="flex flex-row justify-between p-4 w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              <div
                *ngFor="let _ of [].constructor(3)"
                class="t-guest-name flex flex-col gap-4 text-md w-full"
              >
                <p-skeleton width="40%" height="1.25rem"></p-skeleton>
                <p-skeleton width="60%" height="1.25rem"></p-skeleton>
              </div>
            </div>
          </div>
          <div
            class="t-reser-details justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
          >
            <div class="w-[156px] xl:w-[204px] p-4">
              <p-skeleton height="1.5rem" width="160px"></p-skeleton>
            </div>
            <div
              class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="10rem" height="1.25rem"></p-skeleton>
                </div>

                <p-skeleton width="16rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="9rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="16rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="6rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="10rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="8rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="8rem" height="1.25rem"></p-skeleton>
              </div>
            </div>
          </div>
          <div
            class="t-reser-details justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
          >
            <div class="w-[156px] xl:w-[204px] p-4">
              <p-skeleton height="1.5rem" width="170px"></p-skeleton>
            </div>
            <div
              class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="10rem" height="1.25rem"></p-skeleton>
                </div>

                <p-skeleton width="16rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="9rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="13rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="6rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="10rem" height="1.25rem"></p-skeleton>
              </div>
              <div class="flex flex-row gap-2 text-md w-full">
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="8rem" height="1.25rem"></p-skeleton>
                </div>
                <p-skeleton width="15rem" height="1.25rem"></p-skeleton>
              </div>
            </div>
          </div>
          <div
            class="t-reser-details justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
          >
            <div class="w-[156px] xl:w-[204px] p-4">
              <p-skeleton height="1.5rem" width="170px"></p-skeleton>
            </div>
            <div
              class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              <p-skeleton width="60%" height="1.25rem"></p-skeleton>
            </div>
          </div>
          <div
            class="t-reser-details justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
          >
            <div class="w-[156px] xl:w-[204px] p-4">
              <p-skeleton height="1.5rem" width="170px"></p-skeleton>
            </div>
            <div
              class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
            >
              <div
                *ngFor="let _ of [].constructor(2)"
                class="flex flex-row gap-2 text-md w-full"
              >
                <div class="w-[152px] xl:w-[177px]">
                  <p-skeleton width="80%" height="1.25rem"></p-skeleton>
                </div>
                <div class="w-full">
                  <p-skeleton width="100%" height="3rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[348px] xl:min-w-[384px] flex flex-col gap-2">
          <div
            class="bg-white h-[256px] flex flex-col gap-4 p-4 rounded-lg border border-solid border-gray-200"
          >
            <div class="t-infor-header flex flex-row justify-between">
              <p-skeleton height="1.5rem" width="160px"></p-skeleton>
              <p-skeleton height="26px" width="100px"></p-skeleton>
            </div>
            <p-skeleton width="100%" height="94px"></p-skeleton>
            <p-skeleton width="100%" height="78px"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <app-modal
    [showDialog]="showDialogGuestList"
    (emitOnCloseDialog)="showDialogGuestList = false"
    [dialogConfig]="dialogConfig"
    [showFooterDialog]="false"
  >
    <div modal-header class="font-Inter flex flex-col gap-1">
      <h5 class="text-lg font-semibold text-gray-900">
        {{ prefixTranslation + "guest-list.title" | translate }}
      </h5>
    </div>
    <div modal-content class="font-Inter">
      <div class="pb-6">
        <p-table
          [value]="guestList"
          [tableStyle]="{ 'min-width': '50rem' }"
          class="t-table-guest"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>{{ prefixTranslation + "guest-name" | translate }}</th>
              <th>{{ prefixTranslation + "guest-list.age" | translate }}</th>
              <th>{{ prefixTranslation + "phone-number" | translate }}</th>
              <th>{{ "common.e-mail" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-guest>
            <tr>
              <td class="flex flex-row gap-1">
                <p>{{ guest.name }}</p>
                <p
                  *ngIf="guest.role === 'Main'"
                  class="rounded-2xl px-2 py-0.5 text-sm font-medium capitalize text-gray-700 bg-gray-100"
                >
                  {{ guest.role }}
                </p>
              </td>
              <td class="capitalize">{{ guest.age }}</td>
              <td>{{ guest.phone_number }}</td>
              <td>{{ guest.email }}</td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-3 text-md">
          <div class="py-2 px-3 bg-gray-100 rounded-lg flex">
            <p class="w-[206px]">
              {{
                prefixTranslation + "special-requests"
                  | translate
                  | sentenceCase
              }}
            </p>
            <div class="px-4 flex flex-col gap-2">
              <li
                *ngIf="
                  dataBooking?.special_request?.pickup_location &&
                  dataBooking?.special_request.pickup_location.length > 0
                "
              >
                {{ dataBooking.special_request.pickup_location }}
              </li>
              <li
                *ngIf="
                  dataBooking?.special_request?.other &&
                  dataBooking?.special_request.other.length > 0
                "
              >
                {{ dataBooking.special_request.other }}
              </li>
              <span
                *ngIf="
                  !dataBooking?.special_request?.pickup_location &&
                  !dataBooking?.special_request?.other
                "
              >
                {{ prefixTranslation + "no-special-requests" | translate }}
              </span>
              <!-- {{
                  dataBooking && dataBooking.special_request
                    ? dataBooking.special_request.pickup_location
                      ? dataBooking.special_request.pickup_location
                      : (prefixTranslation + "no-special-requests" | translate)
                    : ""
                }} -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-between pt-2 px-6">
        <div class="card flex justify-content-center">
          <p-menu
            #menu
            [model]="exportItems"
            [popup]="true"
            [appendTo]="'body'"
          ></p-menu>
          <button
            pButton
            class="t-guest-action-button flex gap-1 items-center h-11 px-5 py-3 rounded-lg border border-solid border-gray-300 text-md font-semibold text-gray-900"
            (click)="menu.toggle($event)"
          >
            <i
              class="sctr-icon-solid-upload-01-alt text-gray-700 text-base/4"
            ></i>
            {{ "section-action.export" | translate | sentenceCase }}
          </button>
        </div>
        <button
          class="t-guest-action-button h-11 px-5 py-3 rounded-lg border border-solid border-gray-300 text-md font-semibold text-gray-900"
          (click)="showDialogGuestList = false"
        >
          {{ "section-action.close" | translate | sentenceCase }}
        </button>
      </div>
    </div>
  </app-modal>
  <!-- Reject Dialog -->
  <app-reject-dialog
    [reservationCode]="dataBooking ? dataBooking.reservation_code : ''"
    [reservationPinCode]="dataBooking ? dataBooking.pin_code : ''"
    [showDialog]="showRejectDialog"
    (rejectReserSuccessEvent)="rejectReserSuccessHandle()"
    (closeDialogEvent)="closeRejectDialogHandel()"
  ></app-reject-dialog>
</div>
