import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSupportComponent } from './admin-support.component';
import { SoctripChatModule } from '@soctrip-common/chat';
import { AdminSupportRoutingModule } from './admin-support-routing.module';



@NgModule({
  declarations: [
    AdminSupportComponent
  ],
  imports: [
    CommonModule,
    SoctripChatModule,
    AdminSupportRoutingModule,
  ]
})
export class AdminSupportModule { }
