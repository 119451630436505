import { Component, Input, inject } from '@angular/core';
import { FileService } from '@core/services/file.service';

@Component({
  selector: 'app-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss'],
})
export class ReservationCardComponent {
  @Input() tourAvatar = '';
  @Input() tourName = '';
  @Input() packageOption: any = [];
  @Input() comboOption: any = [];
  fileService = inject(FileService);

  ngAfterViewInit() {
    this.adjustTextWithEllipsis();
  }

  getImage(path: string): string {
    let result = '';
    if (path.includes('http')) {
      result = path;
    } else {
      result = this.fileService.getImgWebp(path);
    }
    return result;
  }

  private adjustTextWithEllipsis() {
    const elements = document.querySelectorAll('.text-ellipsis');

    elements.forEach((element) => {
      const htmlElement = element as HTMLElement;
      const originalText = htmlElement.innerText;

      if (htmlElement.scrollWidth > htmlElement.clientWidth) {
        const truncatedText = originalText.slice(0, -1) + '...';
        htmlElement.innerText = truncatedText;
      }
    });
  }
}
