import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AvatarService {
  constructor() {}
  private avatar$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getData() {
    return this.avatar$.asObservable();
  }
  setData(data: string) {
    this.avatar$.next(data);
  }
  getAvatar(avatarThumbnail: string) {
    let avatarUrl = environment.baseURL;
    if (avatarThumbnail && !avatarThumbnail?.includes('/')) {
      avatarUrl += `/storage/files/web/${avatarThumbnail}`;
    } else {
      avatarUrl += '/media/libraries/default/UserAvatar.jpg';
    }
    this.setData(avatarUrl);
  }
}
