import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoundNumberService {
  constructor() {}

  roundNumber(number: number): number {
    const newNumber = Math.round((number + Number.EPSILON) * 1000) / 1000;
    return +(Math.round((newNumber + Number.EPSILON) * 100) / 100);
  }

  roundNumberDown(number: number, decimals: number = 2): number {
    const multiplier = Math.pow(10, decimals);
    const adjustedNumber = number * multiplier;
    const roundedNumber = Math.floor(adjustedNumber);
    return roundedNumber / multiplier;
  }
}
