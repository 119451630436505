<div
  class="t-reser-card p-4 flex flex-row gap-3 rounded-lg border border-solid border-gray-200 bg-white min-h-[124px]"
>
  <img
    [src]="
      tourAvatar
        ? getImage(tourAvatar)
        : '/assets/images/booking/booking_image_default.webp'
    "
    class="h-[92px] w-[92px] rounded-md object-cover aspect-square"
  />
  <div class="flex flex-col gap-1" style="max-width: 88%">
    <p
      class="text-xl font-semibold text-gray-700 font-Inter line-clamp-1 break-words"
      [pTooltip]="tourName"
      tooltipPosition="top"
    >
      {{ tourName }}
    </p>
    <div
      *ngIf="packageOption || comboOption?.id"
      class="flex flex-col flex-1 gap-1"
    >
      <div class="flex gap-2" *ngIf="comboOption?.id">
        <div
          class="min-w-16 font-medium text-gray-500 text-md whitespace-nowrap"
        >
          {{
            "section-booking-helpdesk.booking-helpdesk-detail.reservation-card.combo"
              | translate
          }}
        </div>
        <div class="flex items-center col-span-2 sm:col-span-3">
          <span
            class="mr-2 overflow-hidden font-medium text-gray-700 text-md text-ellipsis whitespace-nowrap"
          >
            {{ comboOption.name }}
          </span>
          <app-tooltip-combo [tourCombo]="comboOption"></app-tooltip-combo>
        </div>
      </div>
      <div class="flex gap-2" *ngIf="packageOption">
        <div
          class="min-w-16 font-medium text-gray-500 text-md whitespace-nowrap"
        >
          {{
            "section-booking-helpdesk.booking-helpdesk-detail.reservation-card.package"
              | translate
          }}
        </div>
        <div class="flex flex-col gap-1" style="max-width: 88%">
          <div class="flex items-center" *ngFor="let package of packageOption">
            <span
              class="mr-2 overflow-hidden font-medium text-gray-700 text-md text-ellipsis whitespace-nowrap"
            >
              {{ package.name }}
            </span>
            <i
              *ngIf="package.description"
              class="sctr-icon-info-circle text-lg/[16px] text-gray-500 cursor-pointer"
              (mouseenter)="
                $event.stopPropagation(); comboOptionsInfoPanel.show($event)
              "
              (mouseleave)="comboOptionsInfoPanel.hide()"
            ></i>
            <i #target class="w-4 h-4 opacity-0 absolute z-[-1]"></i>
            <p-overlayPanel #comboOptionsInfoPanel>
              <div
                class="p-2 text-gray-700 text-md h-auto max-w-96"
                [innerHTML]="package.description"
              ></div>
            </p-overlayPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
