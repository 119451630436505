import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AdminControllerService } from '@assistant/angular-travel-admin-service';
import { SentenceCasePipe } from '@core/pipes/sentence-case.pipe';

@Component({
  selector: 'app-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss'],
})
export class RejectDialogComponent {
  @Input() reservationCode = '';
  @Input() reservationPinCode = '';
  @Input() showDialog = false;
  @Output() rejectReserSuccessEvent = new EventEmitter();
  @Output() closeDialogEvent = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) {}

  private adminControllerService = inject(AdminControllerService);
  private messageService = inject(MessageService);
  private translate = inject(TranslateService);
  private sentenceCasePipe = inject(SentenceCasePipe);
  rejectForm = this.formBuilder.group({
    reject_reason: new FormControl<string | null>(null),
  });
  dialogConfig: DialogConfig = {
    closeFromOutSide: false,
    resizable: false,
    scrollable: true,
    displayCloseButton: true,
    styleClass: 't-dialog-reject w-[588px]',
    appendTo: 'body',
  };
  isCancelling: boolean = false;

  disabledReject(): boolean {
    return !this.rejectForm.value.reject_reason;
  }

  rejectReservation(): void {
    this.isCancelling = true;
    const cancelBody = {
      reservation_code: this.reservationCode,
      pin_code: this.reservationPinCode,
      cancel_reason: this.rejectForm.value.reject_reason?.toString(),
    };
    this.adminControllerService.cancelBookingTourByAdmin(cancelBody).subscribe({
      next: (res) => {
        if (res.success) {
          if (res.data?.success) {
            this.messageService.add({
              severity: 'success',
              detail: this.sentenceCasePipe.transform(
                this.translate.instant('section-action-summary.success', {
                  action: this.translate.instant('section-action.cancel'),
                }),
              ),
            });
            this.rejectReserSuccessEvent.emit();
            this.closeDialog();
          } else {
            this.messageService.add({
              severity: 'error',
              detail: res.data?.error?.message,
            });
          }
        } else {
          this.messageService.add({
            severity: 'error',
            detail: this.sentenceCasePipe.transform(
              this.translate.instant('section-action-summary.failed', {
                action: this.translate.instant('section-action.cancel'),
              }),
            ),
          });
        }
        this.isCancelling = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: this.sentenceCasePipe.transform(
            this.translate.instant('section-action-summary.failed', {
              action: this.translate.instant('section-action.cancel'),
            }),
          ),
        });
        this.isCancelling = false;
      },
    });
  }

  closeDialog(): void {
    this.showDialog = false;
    this.rejectForm.reset();
    this.closeDialogEvent.emit();
  }
}
