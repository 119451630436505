import { Component, inject, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ChatService } from '@shared/services/chat.service';

@Component({
  selector: 'app-admin-support',
  templateUrl: './admin-support.component.html',
  styleUrls: ['./admin-support.component.scss']
})
export class AdminSupportComponent implements OnInit {
  adminProfile: any;
  env = environment;
  chatService = inject(ChatService);
  ngOnInit(): void {
    this.adminProfile = JSON.parse(localStorage.getItem(this.env.USER_PROFILE) || '');
  }

  handleAcceptRequest() {
    this.chatService.pendingSessionChanges.next(true);
  }
}
