<div class="t-dialog">
  <p-dialog
    [closable]="displayCloseButton"
    [(visible)]="showDialog"
    [modal]="true"
    [styleClass]="styleClass + ' t-dialog-' + spacingSize"
    [draggable]="false"
    [dismissableMask]="closeFromOutSide"
    (onHide)="closeDialog()"
    (onShow)="emitOnShowDialog.emit()"
    [resizable]="resizable"
    [position]="position"
    [appendTo]="appendTo"
    closeIcon="sctr-icon-x-close"
    [contentStyleClass]="contentStyleClass"
  >
    <ng-template pTemplate="header">
      <ng-content select="[modal-header]"></ng-content>
    </ng-template>

    <ng-template pTemplate="content">
      <ng-content select="[modal-content]"></ng-content>
    </ng-template>

    <ng-template pTemplate="footer">
      <ng-content select="[modal-footer]"></ng-content>
      <div
        [hidden]="!showCancelBtn && !showConfirmBtn"
        class="flex justify-between gap-3"
      >
        <button
          class="t-footer-btn bg-white border border-solid border-gray-300 text-lg font-semibold text-gray-700 rounded-lg py-[10px] px-[18px] font-Inter"
          *ngIf="showCancelBtn"
          (click)="onCancelDialog()"
        >
          {{ cancelBtnText }}
        </button>
        <button
          class="t-footer-btn t-confirm bg-blue-600 text-white rounded-lg py-[10px] px-[18px] font-Inter text-lg font-semibold disabled:bg-primary-400"
          *ngIf="showConfirmBtn"
          [ngClass]="{
            'py-0 flex items-center gap-2': loading !== undefined
          }"
          (click)="handleSave($event)"
          [disabled]="disabledConfirmBtn"
        >
          <div class="w-5 h-5" *ngIf="loading">
            <p-progressSpinner
              styleClass="opacity-60 w-full h-full"
              strokeWidth="8"
              animationDuration=".5s"
              [style]="{
                width: '1.25rem',
                height: '1.25rem'
              }"
            ></p-progressSpinner>
          </div>
          {{ confirmBtnText }}
        </button>
      </div>
    </ng-template>
  </p-dialog>
</div>
