import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tour-details',
  templateUrl: './tour-details.component.html',
  styleUrls: ['./tour-details.component.scss'],
})
export class TourDetailsComponent {
  @Input() cancelPolicy: any = '';
  @Input() childrenPolicy: any;
  prefixTranslation =
    'section-booking-helpdesk.booking-helpdesk-detail.tour-policy.';
  isRefundable = false;

  constructor(private translationService: TranslateService) {}

  getChildrenPolicy(childPolicy: any) {
    if (childPolicy) {
      if (childPolicy.age_children === 0 && childPolicy.height_children === 0) {
        return `<div class="flex flex-col gap-1 text-[var(--light-color-scheme-gray-900)] text-md font-normal">
          <div class="flex gap-3.5">
            <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>          
            ${this.translationService.instant(
              this.prefixTranslation + 'not_allow_children'
            )}
          </div>
        </div>`;
      } else {
        const childPolicyObj = childPolicy as {
          age_children: number;
          height_children: number;
          charge_children: number;
        };
        return (
          `<div class="flex flex-col gap-1 text-[var(--light-color-scheme-gray-900)] text-md font-normal">
        ` +
          (childPolicyObj.age_children && childPolicyObj.age_children > 0
            ? `<div class="flex gap-3.5">
          <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>
          <span *ngIf="childPolicyObj.age_children && childPolicyObj.age_children > 0">
          ${this.translationService.instant(
            this.prefixTranslation + 'policy_children_age',
            { param: childPolicyObj.age_children }
          )}
          </span>
        </div>`
            : '') +
          `
        ` +
          (childPolicyObj.height_children && childPolicyObj.height_children > 0
            ? `<div class="flex gap-3.5">
          <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>
          <span *ngIf="childPolicyObj.height_children && childPolicyObj.height_children > 0">
          ${this.translationService.instant(
            this.prefixTranslation + 'policy_children_height',
            { param: childPolicyObj.height_children }
          )}
          </span>
        </div>`
            : '') +
          `
        <div class="flex gap-3.5">
          <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>
          <span *ngIf="childPolicyObj.charge_children">
          ${this.translationService.instant(
            this.prefixTranslation + 'policy_children_charge',
            {
              param: childPolicyObj.charge_children,
            }
          )}
          </span>
        </div>
      </div>`
        );
      }
    }
    return '';
  }

  getInfantPolicy(childPolicy: any) {
    if (childPolicy) {
      if (childPolicy.age_infant === 0) {
        return `<div class="flex flex-col gap-1 text-[var(--light-color-scheme-gray-900)] text-md font-normal">
          <div class="flex gap-3.5">
            <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>          
            ${this.translationService.instant(
              this.prefixTranslation + 'not_allow_infants'
            )}
          </div>
        </div>`;
      } else {
        const childPolicyObj = childPolicy as {
          age_children: number;
          height_children: number;
          charge_children: number;
          age_infant: number;
          charge_infant: number;
        };
        return `<div class="flex flex-col gap-1 text-[var(--light-color-scheme-gray-900)] text-md font-normal">
        <div class="flex gap-3.5">
          <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>
          <span *ngIf="childPolicyObj.age_infant">
          ${this.translationService.instant(
            this.prefixTranslation + 'policy_infants_age',
            { param: childPolicyObj.age_infant }
          )}
          </span>
        </div>
        <div class="flex gap-3.5">
          <i class="pi pi-circle-fill text-[5px] text-gray-900 mt-2"></i>
          <span *ngIf="childPolicyObj.charge_infant">
          ${this.translationService.instant(
            this.prefixTranslation + 'policy_infants_charge',
            {
              param: childPolicyObj.charge_infant,
            }
          )}
          </span>
        </div>
      </div>`;
      }
    }
    return '';
  }

  getCancelPolicy() {
    if (
      this.cancelPolicy &&
      this.cancelPolicy.refundable_policies &&
      this.cancelPolicy.refundable_policies.length > 0 &&
      this.cancelPolicy.is_refundable
    ) {
      this.isRefundable = true;
      const curentPolicy = this.cancelPolicy.refundable_policies.find(
        (item: any) => item.is_default,
      );
      if (curentPolicy) {
        return curentPolicy.rules;
      }
      return [];
    } else return [];
  }
}
