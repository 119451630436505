<ng-container *ngIf="stateView === 'INIT'; else appContent">
  <div class="loading-container">
    <div class="loading-bar"></div>
    <!-- <p class="loading-text">{{ 'common.loading.checking_permission' | translate }}...</p> -->
  </div>
</ng-container>
<ng-template #appContent>
  <div
    *ngIf="stateView === 'APP'"
    class="body-wrapper w-full mx-auto flex flex-col bg-gray-50"
  >
    <!-- Virtual header has the same height with <header>, to support UI flows -->
    <header
      class="min-h-[3.5rem] max-h-[3.5rem] w-full bg-transparent border-transparent select-none"
    ></header>
    <!-- Virtual header -->

    <!-- HEADER, with proper z-index for another elements to pop up: dialog, tooltip, ... -->
    <app-header
      class="fixed z-20 h-[3.5rem] min-w-[480px] bg-white shadow-sm w-full"
      [isSmallScreen]="isSmallScreen"
      [user]="user"
      (openSidebar)="sidebarVisible = $event"
    ></app-header>

    <!-- Body -->
    <div
      class="w-full min-h-[calc(100vh_-_3.5rem)] h-auto min-w-[480px] flex flex-grow mx-auto relative"
    >
      <!-- SIDEBAR -->
      <app-sidebar [(sidebarVisible)]="sidebarVisible"></app-sidebar>

      <!-- CONTENT -->
      <div id="body" class="w-full h-full p-6">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
