import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root',
})
export class CopyTextService {
  constructor(private clipboard: Clipboard) {}

  copyToClipboard(text: string): boolean {
    try {
      this.clipboard.copy(text);
      return true; // Copy successful
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      return false; // Copy failed
    }
  }
}
