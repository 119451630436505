import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { AdminControllerService } from '@assistant/angular-travel-admin-service';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import { PriceDetails } from './components/price-details/price-details.component';
import { ReservationStatus } from '@core/enum/payment.config';

@Component({
  selector: 'app-booking-helpdesk',
  templateUrl: './booking-helpdesk-detail.component.html',
  styleUrls: ['./booking-helpdesk-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AdminControllerService],
})
export class BookingHelpdeskDetailComponent {
  @ViewChild(Tooltip) tooltipPrime: Tooltip;
  userAgencyID: any;
  userGroupId: any;
  currentAgencyId: any;
  priceInfo: PriceDetails;
  prefixTranslation = 'section-booking-helpdesk.booking-helpdesk-detail.';
  lang: string = 'en';
  constructor(private http: HttpClient) {
    this.lang = localStorage.getItem('lang') || 'en';
  }

  isShowPhoneNumber = false;
  showDialogGuestList = false;
  guestList: any[] = [];
  reservationId = '';
  showRejectDialog: boolean = false;
  dataBooking: any;
  phoneNumber = '';
  reservationSummaryId = '';
  exportItems: MenuItem[] = [
    {
      label: 'Export file as .csv',
      command: () => {
        this.exportGuestListTo('CSV');
      },
    },
    {
      label: 'Export file as .xlsx',
      command: () => {
        this.exportGuestListTo('XLSX');
      },
    },
    {
      label: 'Export file as .pdf',
      command: () => {
        this.exportGuestListTo('PDF');
      },
    },
  ];
  dialogConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-guest-dialog w-[800px] rounded-xl',
    appendTo: 'body',
    cancelBtn: false,
    confirmBtn: false,
  };
  private activatedRoute = inject(ActivatedRoute);
  private destroy$ = new Subject<void>();
  private adminControllerService = inject(AdminControllerService);

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) => {
          return this.adminControllerService.getDetailReservationByCode(
            params['id'],
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (res) => {
          if (res.success) {
            if (res) {
              this.dataBooking = res.data;
              this.phoneNumber = `(${
                this.dataBooking?.contact_info?.phone!
                  ? this.dataBooking?.contact_info?.phone.dial_code!
                  : ''
              })${
                this.dataBooking?.contact_info?.phone!
                  ? this.dataBooking?.contact_info?.phone?.number!
                  : ''
              }`;
              this.priceInfo = {
                subTotal: this.getSubTotalPrice(
                  this.dataBooking.price_adult,
                  this.dataBooking.price_child,
                  this.dataBooking?.promotions,
                ),
                taxeAndCharge:
                  this.dataBooking.charges.total + this.dataBooking.taxes.total,
                vouchers: this.dataBooking.vouchers || [],
                coinsApplied: this.dataBooking.coins_applied,
                totalPrice: this.dataBooking.net_price,
                adultPrice: this.dataBooking.price_adult,
                childPrice: this.dataBooking.price_child,
                totalAdult: this.dataBooking
                  ? this.dataBooking.total_adults
                  : 0,
                totalChild: this.dataBooking
                  ? this.dataBooking.total_children
                  : 0,
                totalInfant: this.dataBooking
                  ? this.dataBooking?.total_infants
                  : 0,
                infantPrice: this.dataBooking?.price_infant || 0,
                promotions: this.dataBooking?.promotions || [],
                membershipDiscount: this.dataBooking?.membership_discount,
                package_options:
                  this.dataBooking?.package_options?.map((item: any) => ({
                    ...item,
                    isExpand: false,
                  })) || [],
              };
              this.guestList = this.dataBooking.travellers.map((item: any) => {
                if (item.user_type === 'adult') {
                  return {
                    name: item.full_name,
                    role: `${
                      item.email.trim() ===
                        this.dataBooking.contact_info.email &&
                      item.full_name === this.dataBooking.contact_info.full_name
                        ? 'Main'
                        : ''
                    }`,
                    age: item.user_type,
                    phone_number: `(${item.phone.dial_code})${item.phone.number}`,
                    email: item.email,
                  };
                } else {
                  return {
                    name: item.full_name,
                    role: '',
                    age: item.user_type,
                    phone_number:
                      item.phone.dial_code && item.phone.number
                        ? `(${item.phone.dial_code})${item.phone.number}`
                        : '',
                    email: item.email ? item.email : '',
                  };
                }
              });
            }
          }
        },
        error: (err) => {
          console.error(err);
          this.phoneNumber = `(${
            this.dataBooking?.contact_info?.phone!
              ? this.dataBooking?.contact_info?.phone.dial_code!
              : ''
          })${
            this.dataBooking?.contact_info?.phone!
              ? this.dataBooking?.contact_info?.phone?.number!
              : ''
          }`;
          this.priceInfo = {
            subTotal: this.getSubTotalPrice(
              this.dataBooking.price_adult,
              this.dataBooking.price_child,
              this.dataBooking?.promotions,
            ),
            taxeAndCharge:
              this.dataBooking.charges.total + this.dataBooking.taxes.total,
            vouchers: this.dataBooking.vouchers || [],
            coinsApplied: this.dataBooking.coins_applied,
            totalPrice: this.dataBooking.net_price,
            adultPrice: this.dataBooking.price_adult,
            childPrice: this.dataBooking.price_child,
            totalAdult: this.dataBooking ? this.dataBooking.total_adults : 0,
            totalChild: this.dataBooking ? this.dataBooking.total_children : 0,
            promotions: this.dataBooking?.promotions,
          };
          this.guestList = this.dataBooking.travellers.map((item: any) => {
            return {
              name: item.full_name,
              role: `${
                item.email.trim() === this.dataBooking.contact_info.email &&
                item.full_name === this.dataBooking.contact_info.full_name
                  ? 'Main'
                  : ''
              }`,
              age: item.user_type,
              phone_number: `(${item.phone.dial_code})${item.phone.number}`,
              email: item.email,
            };
          });
        },
      });
  }
  getpricePromotion(orgPrice: number, promotion: any): number {
    let price = orgPrice;
    if (promotion && promotion.length > 0) {
      promotion.map((item: any) => {
        price = price - (price * item.total_discount.percent) / 100;
      });
    }
    return price;
  }
  formatStartDate(dateString: string) {
    return moment(dateString).format('MM/DD/YYYY');
  }

  toggleShowPhoneNumber(): void {
    this.isShowPhoneNumber = !this.isShowPhoneNumber;
  }

  viewGuestListHandle(): void {
    this.showDialogGuestList = true;
  }

  getReservationGuests(reservationId: string, type: 'CSV' | 'PDF' | 'XLSX') {
    const url = `${environment.BE_URL}${environment.SERVICES?.['TRAVEL_ADMIN']}/admin/export/reservation-guests?reservation_id=${reservationId}&type=${type}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getSubTotalPrice(
    adultPrice: number,
    childPrice: number,
    promotion: any,
  ): number {
    let subTotal = adultPrice + childPrice;
    if (promotion && promotion.length > 0) {
      promotion.map((item: any) => {
        subTotal = subTotal - item.total_discount.total;
      });
    }
    return subTotal;
  }
  exportGuestListTo(type: 'CSV' | 'PDF' | 'XLSX'): void {
    this.getReservationGuests(this.dataBooking.id, type).subscribe((res) => {
      saveAs(res, `guest-list_${this.dataBooking.id}.${type.toLowerCase()}`);
    });
  }
  getActionByStatus(status: string): number {
    status = status.trim();
    // return 0: No action; 1:Swap ; 2:Reject/Swap; 3:Confirm/Reject/Swap

    if (status === ReservationStatus.CONFIRMED) {
      return 2;
    } else if (status === ReservationStatus.PENDING_CONFIRMATION) return 3;

    return 0;
  }
  rejectReserSuccessHandle() {
    this.fetchData();
  }

  closeRejectDialogHandel(): void {
    this.showRejectDialog = false;
  }
}
