import { Component, Input, OnDestroy, ViewChild, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { MessageService } from 'primeng/api';
import { CopyTextService } from 'src/app/core/services/copy-text.service';
import { environment } from '@env/environment';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { ReservationStatus } from '@core/enum/payment.config';
import { Tooltip } from 'primeng/tooltip';
import { AppRoutes } from '@core/enum/routes.enum';
import { AdminControllerService } from '@assistant/angular-travel-admin-service';

@Component({
  selector: 'app-booking-information',
  templateUrl: './booking-information.component.html',
  styleUrls: ['./booking-information.component.scss'],
  providers: [AdminControllerService],
})
export class BookingInformationComponent implements OnDestroy {
  @ViewChild(Tooltip) tooltipPrime: Tooltip;
  @Input() reservationCode = '';
  @Input() reservationStatus = '';
  @Input() reservationNetlPrice = 0;
  @Input() reservationCommissPrice = 0;
  @Input() reservationCommissPercent = 0;
  @Input() qrCode = '';
  @Input() pinCode = '';
  private http = inject(HttpClient);
  private copyTextService = inject(CopyTextService);
  private messageService = inject(MessageService);
  private translator = inject(CustomTranslateService);
  private destroy$ = new Subject<void>();
  private adminControllerService = inject(AdminControllerService);
  baseUrl = environment.BE_URL;
  isShowQrCode: boolean = false;
  isLoadImage: boolean = false;
  qrImageUrl: string;
  dialogConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-qr-code',
    appendTo: 'body',
    cancelBtn: false,
    cancelBtnText: 'Save',
    confirmBtn: false,
    confirmBtnText: 'Copy link',
  };
  pageTitle = environment['PAGE_TITLE'];
  prefixTranslation =
    'section-booking-helpdesk.booking-helpdesk-detail.booking-information.';

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getClassStatus(status: string): string {
    status = status.trim();
    switch (status) {
      case ReservationStatus.PENDING_CONFIRMATION:
      case ReservationStatus.PENDING_PAYMENT:
        return 'pending';
      case ReservationStatus.CONFIRMED:
        return 'confirmed';
      case ReservationStatus.IN_PROGRESS:
        return 'progress';
      case ReservationStatus.PENDING_REVIEW:
      case ReservationStatus.COMPLETED:
        return 'completed';
      case ReservationStatus.PAYMENT_FAILED:
      case ReservationStatus.CANCELED:
      case ReservationStatus.REFUNDED:
        return 'canceled';
      default:
        return 'canceled';
    }
  }

  capitalizeFirstLetter(sentence: string) {
    // Chuyển ký tự đầu tiên của câu thành chữ hoa và ghép với phần còn lại của câu
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  onCopy(copyText: string): void {
    navigator.clipboard.writeText(copyText);
    if (!this.tooltipPrime.active) {
      this.tooltipPrime.activate();
    }
    setTimeout(() => {
      this.tooltipPrime.deactivate();
    }, 500);
  }

  handlePrint(): void {
    const payload: any = {
      reservation_code: this.reservationCode,
      pin_code: this.pinCode,
    };

    this.adminControllerService.generatePDF(payload).subscribe({
      next: (data: any) => {
        if (data.success) {
          const url = `${this.baseUrl}storage/files/download/${data.data.id}${data.data.extension}`;
          window.open(url);
        } else {
          this.messageService.add({
            severity: 'error',
            detail: 'Generate PDF failed!',
          });
        }
      },
      error: (err: any) => {
        this.messageService.add({
          severity: 'error',
          detail: 'Generate PDF failed!',
        });
      },
    });
  }

  handleInItQRCode(): void {
    this.isShowQrCode = true;
    this.qrImageUrl =
      this.baseUrl + 'storage/files/web/' + this.qrCode + '.webp';
    if (this.qrImageUrl.includes('undefined')) {
      this.isLoadImage = false;
    } else {
      this.isLoadImage = true;
    }
  }

  onSaveQRCode() {
    const imageUrl = `${this.baseUrl}storage/files/download/${this.qrCode}`;
    this.http.get(imageUrl, { responseType: 'blob' }).subscribe((val) => {
      const url = URL.createObjectURL(val);
      const a: any = document.createElement('a');
      a.href = url;
      a.download = 'QR_Code_' + this.reservationCode;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    });
  }

  onCopyQRCode() {
    const link =
      window.location.origin +
      '/' +
      AppRoutes.BOOKING_HELPDESK +
      '/' +
      this.reservationCode;
    const success = this.copyTextService.copyToClipboard(link);
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: this.translator.sentenceCase(`common.success`),
        detail: `${this.translator.sentenceCase(
          'common.copied',
        )} ${this.translator.transform('common.link')}`,
      });
    }
  }
}
