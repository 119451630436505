import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { CustomTranslateService } from './services/custom-translate.service';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { RoundNumberService } from './services/round-number.service';
import { TimezoneFormatOffsetPipe } from './pipes/timezone-format-offset.pipe';

@NgModule({
  declarations: [SentenceCasePipe, CurrencySymbolPipe, TimezoneFormatOffsetPipe],
  imports: [CommonModule, RouterModule],
  exports: [SentenceCasePipe, CurrencySymbolPipe, TimezoneFormatOffsetPipe],
  providers: [
    CustomTranslateService,
    TitleCasePipe,
    SentenceCasePipe,
    CurrencySymbolPipe,
    RoundNumberService,
    TimezoneFormatOffsetPipe
  ],
})
export class CoreModule {}
