import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CoreModule } from '../core/core.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { SidebarModule } from 'primeng/sidebar';
import { HeaderComponent } from './components/header/header.component';
import { Countries } from '../core/models/classes/countries';
import { TranslateModule } from '@ngx-translate/core';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { DragAndDropDirective } from '../directives/drag-and-drop.directive';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { InputTextModule } from 'primeng/inputtext';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CustomUploadImgComponent } from './components/custom-upload-img/custom-upload-img.component';
import { CustomPreviewVoucherComponent } from './components/custom-preview-voucher/custom-preview-voucher.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { AlphaNumericDirective } from '../directives/alpha-numeric.directive';
import { TabViewComponent } from './components/tabview/tabview.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ModalComponent } from './components/modal/modal.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CurrencySymbolPipe } from '../core/pipes/currency-symbol.pipe';
import { CommonDropdownComponent } from './components/dropdowns/common-dropdown/common-dropdown.component';
import { CustomizePaginationComponent } from '../features/booking-helpdesk/components/customize-pagination/customize-pagination.component';
import { SafePipe } from './pipe/safe-url.pipe';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { SliderModule } from 'primeng/slider';
import { NoResultComponent } from './components/no-result-found/no-result-found.component';
import { CustomDateTimePipe } from '../features/promotion-management/date-time.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
@NgModule({
  declarations: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    FormSectionComponent,
    DragAndDropDirective,
    PaginatorComponent,
    CustomUploadImgComponent,
    CustomPreviewVoucherComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    AdminLayoutComponent,
    ModalComponent,
    CommonDropdownComponent,
    CustomizePaginationComponent,
    SafePipe,
    CustomDateTimePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    TranslateModule,
    ToastModule,
    SkeletonModule,
    DialogModule,
    ProgressSpinnerModule,
    BadgeModule,
    TooltipModule,
    RadioButtonModule,
    ReactiveFormsModule,
    CalendarModule,
    TabMenuModule,
    SliderModule,
    MenuModule,
    NoResultComponent,
    CheckboxModule,
    OverlayPanelModule,
    OverlayModule,
  ],
  exports: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    FormSectionComponent,
    DragAndDropDirective,
    SentenceCasePipe,
    CurrencySymbolPipe,
    PaginatorComponent,
    CustomUploadImgComponent,
    CustomPreviewVoucherComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    ModalComponent,
    CommonDropdownComponent,
    CustomizePaginationComponent,
    SkeletonModule,
    RadioButtonModule,
    MenuModule,
    TabMenuModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    SliderModule,
    SafePipe,
    CustomDateTimePipe,
    NoResultComponent,
    CheckboxModule,
    OverlayModule,
  ],
  providers: [Countries],
})
export class SharedModule {}
