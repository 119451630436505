import { BookingHelpdeskDetailComponent } from './booking-helpdesk-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { BookingInformationComponent } from './components/booking-information/booking-information.component';
import { ReservationCardComponent } from './components/reservation-card/reservation-card.component';
import { TourDetailsComponent } from './components/tour-details/tour-details.component';
import { PriceDetailsComponent } from './components/price-details/price-details.component';
import { RejectDialogComponent } from './components/reject-dialog/reject-dialog.component';
import { TooltipComboComponent } from './components/tooltip-combo/tooltip-combo.component';

import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { BookingHelpdeskDetailRoutingModule } from './booking-helpdesk-detail-routing.module';

import { AdminControllerService } from '@assistant/angular-travel-admin-service';

@NgModule({
  declarations: [
    BookingDetailsComponent,
    BookingHelpdeskDetailComponent,
    BookingInformationComponent,
    ReservationCardComponent,
    TourDetailsComponent,
    PriceDetailsComponent,
    RejectDialogComponent,
    TooltipComboComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    MenuModule,
    SharedModule,
    CoreModule,
    BookingHelpdeskDetailRoutingModule,
    SkeletonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayPanelModule,
  ],
  providers: [AdminControllerService],
})
export class BookingHelpdeskDetailModule {}
