import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

export interface ITimezone {
  id: string;
  offset: string;
  full: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  getoffsetByTimezone(timezone: string, isOffsetOnly: boolean = false) {
    const offset = moment().tz(timezone).format('Z');
    return isOffsetOnly ? `UTC${offset}` : `(UTC${offset}) ${timezone}`
  }
}