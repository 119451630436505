<app-modal [showDialog]="showDialog" (emitOnCloseDialog)="closeDialog()" [dialogConfig]="dialogConfig">
  <div modal-header>
    <div class="flex flex-col w-full gap-1">
      <h1 class="text-gray-900 font-semibold text-xl">
        {{'section-booking-helpdesk.booking-helpdesk-detail.cancel-form.title' | translate}}</h1>
    </div>
  </div>

  <form [formGroup]="rejectForm" class="flex flex-col gap-4" modal-content>
    <div class="flex flex-col md:flex-row gap-4">
      <div class="flex flex-col gap-1.5 w-full">
        <h4 class="text-lg font-semibold text-gray-700">
          {{'section-booking-helpdesk.booking-helpdesk-detail.cancel-form.cancel-confirm-question' | translate}}
        </h4>
        <div class="flex gap-1 text-md">
          <h4 class="text-md font-medium text-gray-700">
            {{'section-booking-helpdesk.booking-helpdesk-detail.cancel-form.cancel-confirm-description' | translate}}
          </h4>
          <span class="text-error-600">*</span>
        </div>
        <textarea rows="5" pInputTextarea formControlName="reject_reason"
          class="w-full border border-gray-200 rounded-lg focus-visible:border p-3 text-md font-normal text-gray-700"
          placeholder="{{'section-booking-helpdesk.booking-helpdesk-detail.cancel-form.reason-textarea-placeholder' | translate}}"></textarea>
      </div>
    </div>
  </form>
  <div modal-footer>
    <div class="flex flex-row gap-3 justify-end">
      <button
        class="t-footer-btn min-w-[88px] h-11 capitalize text-base items-center px-[18px] bg-white font-semibold text-gray-700 rounded-lg border border-solid border-gray-300"
        type="submit" (click)="closeDialog()">
        {{ 'section-action.close' | translate}}
      </button>
      <button type="submit"
        class="t-footer-btn min-w-[88x] h-11 capitalize text-base leading-10 px-[18px] font-semibold text-white rounded-lg border border-solid"
        [ngClass]="
          disabledReject() || isCancelling ? 'bg-error-200 border-error-200' : 'bg-error-600 border-error-600'
        " [disabled]="disabledReject()" (click)="rejectReservation()">
        <span *ngIf="isCancelling" class="text-[1rem] pi pi-spin pi-spinner"></span>
        {{ 'section-action.cancel' | translate}}
      </button>
    </div>
  </div>
</app-modal>