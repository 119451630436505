export enum PaymentType {
  PAY_NOW = 'PAY_NOW',
  PAY_LATER = 'PAY_LATER',
}

export enum ReservationStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
}
