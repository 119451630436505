<div class="flex relative">
  <i
    class="sctr-icon-info-circle text-lg/[16px] text-gray-500 cursor-pointer"
    (mouseenter)="
      $event.stopPropagation();
      isDisplayedPanelOnTop
        ? comboOptionsInfoPanel.show($event, target)
        : comboOptionsInfoPanel.show($event)
    "
    (mouseleave)="comboOptionsInfoPanel.hide()"
  ></i>
  <i #target class="w-4 h-4 opacity-0 absolute z-[-1]"></i>
  <p-overlayPanel #comboOptionsInfoPanel>
    <div class="flex flex-col py-2 px-3 gap-1">
      <div
        *ngFor="let option of comboOptions"
        class="flex items-start justify-between gap-6"
      >
        <div class="flex items-center gap-2">
          <span class="font-medium text-gray-700 text-md whitespace-nowrap">
            {{ option.name }}:
          </span>
        </div>
        <div class="flex flex-col items-end text-gray-700 text-md">
          <span *ngFor="let value of option?.value">{{ value }}</span>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</div>
