<div
  class="t-reser-details justify-between flex flex-row border border-solid border-gray-200 bg-white rounded-lg"
>
  <p
    class="p-4 whitespace-nowrap text-base font-semibold text-gray-900 w-[156px] xl:w-[204px]"
  >
    {{ prefixTranslation + "title" | translate }}
  </p>
  <div
    class="p-4 flex flex-col gap-3 text-md font-normal text-gray-900 w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
  >
    <span class="grid grid-cols-12">
      <p
        class="col-span-5 xl:col-span-4 font-medium text-gray-500 w-[152px] xl:w-[177px] whitespace-nowrap"
      >
        {{ prefixTranslation + "cancellation-policy" | translate }}
      </p>
      <div class="t-my-booking-card col-span-7 xl:col-span-8">
        <div class="flex flex-col gap-1">
          <div
            *ngFor="let ruleItem of getCancelPolicy(); let ruleIndex = index"
          >
            <ng-container *ngIf="ruleIndex === 0; else elseTemplate">
              <li class="text-md font-normal text-gray-700" *ngIf="ruleItem.to">
                {{
                  "section-ota.policies.policySummaryFree"
                    | translate: { from: ruleItem.to }
                }}
              </li>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="flex flex-col gap-2 items-start">
                <ng-template
                  *ngTemplateOutlet="
                    cancelRules;
                    context: { $implicit: ruleItem }
                  "
                ></ng-template>
              </div>
            </ng-template>
          </div>
          <li
            *ngIf="!isRefundable || getCancelPolicy().length === 0"
            class="text-md font-normal text-gray-700"
          >
            {{ "section-ota.policies.noRefundable" | translate }}
          </li>
          <li class="text-md font-normal text-gray-700">
            {{ "section-ota.policies.policySummaryNoShow" | translate }}
          </li>
          <ng-template #cancelRules let-ruleItem>
            <li
              class="text-md font-normal text-gray-700"
              *ngIf="ruleItem.from && ruleItem.to && ruleItem.to !== 0"
            >
              {{
                "section-ota.policies.policySummaryFromTo"
                  | translate
                    : {
                        from: ruleItem.to,
                        to: ruleItem.from,
                        charge: !ruleItem.charge ? 0 : ruleItem.charge
                      }
              }}
            </li>
            <li
              class="text-md font-normal text-gray-700"
              *ngIf="
                (ruleItem.from && !ruleItem.to) ||
                (ruleItem.from && ruleItem.to === 0)
              "
            >
              {{
                "section-ota.policies.policySummary1Day"
                  | translate
                    : {
                        from: ruleItem.from,
                        day:
                          ruleItem.from > 1
                            ? ("section-ota.policies.days" | translate | lowercase)
                            : ("section-ota.policies.day" | translate | lowercase),
                        charge: !ruleItem.charge ? 0 : ruleItem.charge
                      }
              }}
            </li>
          </ng-template>
        </div>
      </div>
    </span>

    <span
      class="grid grid-cols-12"
      *ngIf="
        getChildrenPolicy(childrenPolicy ? childrenPolicy : '') ||
        getInfantPolicy(childrenPolicy ? childrenPolicy : '')
      "
    >
      <p class="col-span-4 text-[var(--light-color-scheme-gray-500)] w-[177px] whitespace-nowrap">
        {{ this.prefixTranslation +'children-policy' | translate }}
      </p>
      <div class="col-span-8 flex flex-col gap-1">
        <div
          [innerHTML]="
            getInfantPolicy(childrenPolicy ? childrenPolicy : '')
          "
        ></div>
        <div
          [innerHTML]="
            getChildrenPolicy(
              childrenPolicy ? childrenPolicy : ''
            )
          "
        ></div>
      </div>
    </span>
  </div>
</div>
