<div
  class="t-reser-details flex justify-between flex-row border border-solid border-gray-200 bg-white rounded-lg"
>
  <p
    class="p-4 whitespace-nowrap text-base font-semibold text-gray-900 w-[156px] xl:w-[204px]"
  >
    {{ prefixTranslation + "title" | translate }}
  </p>
  <div
    class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-156px)] xl:w-[calc(100%-204px)]"
  >
    <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "departure-date" | translate }}
      </p>
      <p class="text-900 w-[calc(100%-177px)]">
        {{ formatDate(departureDate, startTime, durationUnit) }} ({{
          timezone ? (timezone | timezoneFormatOffset) : ""
        }})
      </p>
    </span>
    <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "end-date" | translate }}
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)]">
        {{ formatDate(endDate, endTime, durationUnit) }} ({{
          timezone ? (timezone | timezoneFormatOffset) : ""
        }})
      </p>
    </span>
    <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "duration" | translate }}
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)]">
        {{ duration }} {{ durationUnit }}
      </p>
    </span>
    <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "guests" | translate }}
      </p>
      <div class="flex flex-col xl:flex-row gap-2 w-[calc(100%-177px)]">
        <p class="text-gray-900">
          {{ adultNumber }} {{ adultNumber > 1 ? "adults" : "adult"
          }}<ng-container *ngIf="childNumber > 0"
            >, {{ childNumber }} {{ childNumber > 1 ? "children" : "child" }},
          </ng-container>
          <ng-container *ngIf="infantNumber > 0">
            {{ infantNumber }} {{ infantNumber > 1 ? "infants" : "infant" }}
          </ng-container>
        </p>
        <button
          class="bg-blue-50 w-fit text-md font-medium text-blue-700 rounded-md"
          (click)="viewGuestList()"
        >
          {{ prefixTranslation + "view-guest-list" | translate }}
        </button>
      </div>
    </span>
  </div>
</div>
