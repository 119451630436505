import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { ModerateRole } from '@shared/constant';
import { UserService } from '@core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  accessRole = [
    // ModerateRole.MEMBER.code,
    // ModerateRole.COLLABORATORS.code,
    ModerateRole.ADMIN.code,
    ModerateRole.SUPER_ADMIN.code,
    // ModerateRole.LEADER.code,
    // ModerateRole.MANAGER.code,
  ];

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const authStatus = JSON.parse(
      localStorage.getItem(environment.AUTH_STATUS) || '{}',
    );
    const userProfile = JSON.parse(
      localStorage.getItem(environment.USER_PROFILE) || '{}',
    );
    if (!authStatus.isLoggedIn || !userProfile) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return this.userService.getModeratorRole().pipe(
        map((res) => {
          if (this.accessRole.includes(res[0].role)) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }),
      );
    }
  }
}
