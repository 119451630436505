import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '@core/services/timezone.service';

@Pipe({
  name: 'timezoneFormatOffset'
})
export class TimezoneFormatOffsetPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) { }
  transform(tz: string, isOffsetOnly: boolean = true): string {
    return this.timezoneService.getoffsetByTimezone(tz, isOffsetOnly);
  }

}
